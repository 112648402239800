
import { defineComponent } from 'vue';
import ExternalLink from '../components/ExternalLink.vue';

export default defineComponent({
  name: 'About',
  components: {
    ExternalLink
  },
});
